import { AnyAction, Dispatch } from 'redux';

import { DASHBOARD_PATH, AUTHENTICATION_PATH } from '@routes';
import { REDIRECT } from '@actions';
import { LOGIN_SUCCESS, LOGOUT_REQUEST } from '@actions/session';
import { clearCookie, CookiesKeys, setCookie } from '@utils/cookies';
import { setAuthorizationHeader, clearAuthorizationHeader } from '@config/axios';

export default () => (next: Dispatch) => (action: AnyAction) => {
  const { type, payload } = action;

  if (type === LOGIN_SUCCESS) {
    setCookie(CookiesKeys.sessionToken, payload.token);
    setAuthorizationHeader(payload.token);
    next(action);

    return next({
      type: REDIRECT,
      payload: {
        redirectPath: DASHBOARD_PATH,
      },
    });
  }

  if (type === LOGOUT_REQUEST) {
    clearCookie(CookiesKeys.sessionToken);
    clearAuthorizationHeader();
    next(action);

    return next({
      type: REDIRECT,
      payload: {
        redirectPath: AUTHENTICATION_PATH,
      },
    });
  }

  return next(action);
};
