import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  page: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  pageContent: {
    padding: '80px 16px 40px 16px',
    flexGrow: 1,
  },
});
