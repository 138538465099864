import {
  get,
  set,
  remove,
  clear,
} from 'local-storage';

export enum StorageKeys {
  theme = 'theme',
}

export const getKey = (key: StorageKeys): any => get(key);

export const setKey = (key: StorageKeys, value: any) => set(key, value);

export const clearKey = (key: StorageKeys) => remove(key);

export const clearStorage = () => clear();
