import axios from 'axios';

const PROFILE_URL = '/profile';
const EMAIL_VERIFICATION_URL = '/resend-verify-email';

type VerificationEmailValues = {
  email: string;
};

export const fetchUserProfile = (): Promise<any> => (
  axios.get(PROFILE_URL)
);

export const sendVerificationEmail = (values: VerificationEmailValues): Promise<any> => (
  axios.post(EMAIL_VERIFICATION_URL, { ...values })
);
