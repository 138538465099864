import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useParams } from 'react-router-dom';

import { customerFetchRequest, customerResetRequest } from '@actions/customers';
import Loader from '@components/loader';
import Error from '@pages/error/components/root';

type StateProps = {
  loading: boolean;
  error: string;
  isCustomerFetched: boolean;
}

export type DispatchProps = {
  actions: {
    customerFetchRequest: ({ customerId }) => void;
    customerResetRequest: () => void;
  };
}

export default function withCustomer<P extends object>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<P> {
  const Component = ({
    loading,
    isCustomerFetched,
    actions,
    error,
    ...rest
  }: StateProps & DispatchProps) => {
    const params = useParams<{id}>();

    React.useEffect(() => {
      if (!isCustomerFetched) {
        actions.customerFetchRequest({ customerId: params.id });
      }

      return () => actions.customerResetRequest();
    }, []);

    if (loading || (!isCustomerFetched && !error)) {
      return <Loader message="Fetching customer data..." />;
    }

    if (error) {
      return <Error message="Couldn't retrieve customer data. Please try again later." />;
    }

    return <WrappedComponent {...rest as P} />;
  };

  const mapStateToProps = (state: Goaco.State): StateProps => ({
    loading: state.customer.requestInProgress,
    error: state.customer.error,
    isCustomerFetched: !!state.customer.uuid,
  });

  const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    actions: {
      ...bindActionCreators({
        customerFetchRequest,
        customerResetRequest,
      }, dispatch),
    },
  });

  return connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(Component);
}
