import { SagaIterator } from 'redux-saga';
import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import {
  KYC_CHECKS_FETCH_REQUEST,
  kycChecksFetchSuccess,
  kycChecksFetchError,
} from '@actions/data-providers';
import { fetchKycChecks } from '@services/data-providers';

export function* kycChecksRequest(): SagaIterator {
  try {
    const { data } = yield call(fetchKycChecks);
    yield put(kycChecksFetchSuccess({ list: data }));
  } catch (error) {
    yield put(kycChecksFetchError({
      error: error.response?.data?.message || error.message,
      status: error.response?.status,
    }));
  }
}

export function* watchDataProvidersRequests(): SagaIterator {
  yield takeLatest(KYC_CHECKS_FETCH_REQUEST, kycChecksRequest);
}
