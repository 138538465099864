/* eslint-disable no-param-reassign */
import { AnyAction } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import produce, { Draft } from 'immer';

import { logoutRequest } from '@actions/session';
import {
  silentCompanyFetchRequest,
  companyFetchRequest,
  companyFetchSuccess,
  companyFetchError,
  enableBalanceRefresh,
} from '@actions/company';

export default createReducer({}, {
  [silentCompanyFetchRequest]: produce((state: Draft<Goaco.Company>) => {
    state.requestInProgress = false;
    state.error = '';
  }),
  [companyFetchRequest]: produce((state: Draft<Goaco.Company>) => {
    state.requestInProgress = true;
    state.error = '';
  }),
  [companyFetchSuccess]: produce((state: Draft<Goaco.Company>, action: AnyAction) => ({
    ...state,
    requestInProgress: false,
    ...action.payload,
  })),
  [companyFetchError]: produce((state: Draft<Goaco.Company>, action: AnyAction) => {
    state.requestInProgress = false;
    state.error = action.payload.error;
  }),
  [enableBalanceRefresh]: produce((state: Draft<Goaco.Company>, action: AnyAction) => ({
    ...state,
    balanceRefreshEnabled: action.payload,
  })),
  [logoutRequest]: produce(() => ({})),
});
