/* eslint-disable no-param-reassign */
import { AnyAction } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import produce, { Draft } from 'immer';

import { logoutRequest } from '@actions/session';
import {
  customerFetchRequest,
  customerFetchSuccess,
  customerFetchError,
  customerResetRequest,
} from '@actions/customers';

export default createReducer({}, {
  [customerFetchRequest]: produce((state: Draft<Goaco.Customer>) => {
    state.requestInProgress = true;
    state.error = '';
  }),
  [customerFetchSuccess]: produce((state: Draft<Goaco.Customer>, action: AnyAction) => ({
    requestInProgress: false,
    ...action.payload.details,
    activity: action.payload.activity,
  })),
  [customerFetchError]: produce((state: Draft<Goaco.Customer>, action: AnyAction) => {
    state.requestInProgress = false;
    state.error = action.payload.error;
  }),
  [customerResetRequest]: produce(() => ({})),
  [logoutRequest]: produce(() => ({})),
});
