/* eslint-disable no-param-reassign */
import { AnyAction } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import produce, { Draft } from 'immer';

import { logoutRequest } from '@actions/session';
import {
  emailVerificationRequest,
  emailVerificationSuccess,
  emailVerificationError,
} from '@actions/email-verification';

export default createReducer({}, {
  [emailVerificationRequest]: produce((state: Draft<Goaco.EmailVerification>) => {
    state.requestInProgress = true;
    state.error = '';
  }),
  [emailVerificationSuccess]: produce((state: Draft<Goaco.EmailVerification>) => {
    state.requestInProgress = false;
    state.done = true;
  }),
  [emailVerificationError]: produce((state: Draft<Goaco.EmailVerification>, action: AnyAction) => {
    state.requestInProgress = false;
    state.error = action.payload.error;
  }),
  [logoutRequest]: produce(() => ({})),
});
