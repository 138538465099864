import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { clientTransactionsRequest } from '@actions/client';
import Loader from '@components/loader';
import Error from '@pages/error/components/root';

type StateProps = {
  loading: boolean;
  error: string;
  manualVisitSubscriptionEnabled: boolean;
};

export type DispatchProps = {
  actions: {
    clientTransactionsRequest: ({ pageNumber: Number }) => void;
  };
};

export default function withClient<P extends object>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<P> {
  const Component = ({
    loading,
    manualVisitSubscriptionEnabled,
    actions,
    error,
    ...rest
  }: StateProps & DispatchProps) => {
    React.useEffect(() => {
      if (manualVisitSubscriptionEnabled) {
        actions.clientTransactionsRequest({
          pageNumber: 1,
        });
      }
    }, [manualVisitSubscriptionEnabled]);
    const props = { ...rest, actions };

    if (loading && !error) {
      return <Loader message="Fetching client data..." />;
    }

    if (error) {
      return <Error message="Couldn't retrieve client data. Please try again later." />;
    }

    return <WrappedComponent {...(props as P)} />;
  };

  const mapStateToProps = (state: any): StateProps => ({
    loading: state.client.requestInProgress,
    error: state.client.error,
    manualVisitSubscriptionEnabled: state.client.status,
  });

  const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    actions: {
      ...bindActionCreators(
        {
          clientTransactionsRequest,
        },
        dispatch,
      ),
    },
  });

  return connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(Component);
}
