import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles({
  bannerWrapper: {
    padding: '32px 16px 16px',
  },
  banner: {
    maxWidth: 800,
    margin: '0 auto',
  },
  chip: {
    fontWeight: 600,
    backgroundColor: theme.palette.secondary.main,
  },
  bannerInfo: {
    flexGrow: 1,
    paddingLeft: 16,
  },
  feedbackLink: {
    marginLeft: 5,
    marginRight: 5,
    textDecoration: 'underline',
  },
});
