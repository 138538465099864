import React from 'react';
import { Switch, Route } from 'react-router';

import Header from '@header/components/root';
import Footer from '@footer/components/root';
import Banner from '@banner/components/root';

import styles from './styles';

type RouteProps = {
  path: string;
  exact?: boolean;
  component: React.ComponentType<any>;
}

type Props = {
  routes: RouteProps[];
}

const App: React.FC<Props> = ({ routes }: Props) => {
  const classes = styles();

  return (
    <div className={classes.page}>
      <Header />
      <Banner />
      <div className={classes.pageContent}>
        <Switch>
          {routes.map((route: RouteProps) => (
            <Route
              key={`path-${route.path}`}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </div>
      <Footer />
    </div>
  );
};

export default App;
