import React from 'react';
import {
  Typography,
  Grid,
  Chip,
  Link,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import ConfigContext from '@context/config';

import styles from './styles';

const Banner: React.FC<{}> = () => {
  const theme = useTheme();
  const classes = styles(theme)();
  const { feedbackUrl } = React.useContext(ConfigContext);

  return (
    <div className={classes.bannerWrapper}>
      <Grid
        container
        alignItems="center"
        wrap="nowrap"
        className={classes.banner}
      >
        <Chip className={classes.chip} label="ALPHA" />
        <Typography className={classes.bannerInfo} component="p" variant="body2">
          The dashboard is in alpha.
          We are continuously updating the service.
          Your
          <Link
            className={classes.feedbackLink}
            href={feedbackUrl}
            target="_blank"
            rel="noopener noreferrer"
            color="textPrimary"
          >
            feedback
          </Link>
          will help us improve it.
        </Typography>
      </Grid>
    </div>
  );
};

export default Banner;
