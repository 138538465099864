/* eslint-disable no-param-reassign */
import { AnyAction } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import produce, { Draft } from 'immer';

import {
  loginRequest,
  loginSuccess,
  loginError,
  logoutRequest,
} from '@actions/session';

export default createReducer({}, {
  [loginRequest]: produce((state: Draft<Goaco.Session>) => {
    state.requestInProgress = true;
    state.error = '';
  }),
  [loginSuccess]: produce((state: Draft<Goaco.Session>, action: AnyAction) => ({
    ...state,
    requestInProgress: false,
    ...action.payload,
  })),
  [loginError]: produce((state: Draft<Goaco.Session>, action: AnyAction) => {
    state.requestInProgress = false;
    state.error = action.payload.error;
  }),
  [logoutRequest]: produce(() => ({})),
});
