import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import {
  LOGIN_REQUEST,
  loginSuccess,
  loginError,
  PASSWORD_RESET_LINK_REQUEST,
  PASSWORD_RESET_REQUEST,
} from '@actions/session';
import {
  login,
  resetPassword,
  sendResetPasswordLink,
} from '@services/session';
import { formatObjectToCamelCase, formatApiErrors } from '@utils/formatters';

export function* loginRequest(action: AnyAction): SagaIterator {
  const { values, setSubmitting } = action.payload;

  try {
    const { data } = yield call(login, values);
    yield put(loginSuccess({ ...formatObjectToCamelCase(data) }));
  } catch (error) {
    yield put(loginError({
      error: error.response?.data?.message || error.message,
      status: error.response?.status,
      noRedirect: true,
    }));
    setSubmitting(false);
  }
}

export function* resetPasswordLinkRequest(action: AnyAction): SagaIterator {
  const {
    values,
    onSuccess,
    onError,
  } = action.payload;

  try {
    yield call(sendResetPasswordLink, values);
    onSuccess();
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorsList = error.response?.data?.form?.children || {};
    onError({ errorMessage, errorsList: formatApiErrors(errorsList) });
  }
}

export function* passwordResetRequest(action: AnyAction): SagaIterator {
  const {
    token,
    values,
    onSuccess,
    onError,
  } = action.payload;

  try {
    yield call(resetPassword, { token, password: values.password });
    onSuccess();
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorsList = error.response?.data?.form?.children || {};
    onError({ errorMessage, errorsList: formatApiErrors(errorsList) });
  }
}

export function* watchSessionRequests(): SagaIterator {
  yield takeLatest(LOGIN_REQUEST, loginRequest);
  yield takeLatest(PASSWORD_RESET_LINK_REQUEST, resetPasswordLinkRequest);
  yield takeLatest(PASSWORD_RESET_REQUEST, passwordResetRequest);
}
