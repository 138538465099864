import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { customersFetchRequest } from '@actions/customers';
import Loader from '@components/loader';
import Error from '@pages/error/components/root';

type StateProps = {
  loading: boolean;
  error: string;
}

export type DispatchProps = {
  actions: {
    customersFetchRequest: () => void;
  };
}

export default function withCustomers<P extends object>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<P> {
  const Component = ({
    loading,
    actions,
    error,
    ...rest
  }: StateProps & DispatchProps) => {
    React.useEffect(() => {
      actions.customersFetchRequest();
    }, []);

    if (loading && !error) {
      return <Loader message="Fetching company customers..." />;
    }

    if (error) {
      return <Error message="Couldn't retrieve company customers. Please try again later." />;
    }

    return <WrappedComponent {...rest as P} />;
  };

  const mapStateToProps = (state: Goaco.State): StateProps => ({
    loading: state.customers.requestInProgress,
    error: state.customers.error,
  });

  const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    actions: {
      ...bindActionCreators({
        customersFetchRequest,
      }, dispatch),
    },
  });

  return connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(Component);
}
