import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put } from 'redux-saga/effects';

import {
  CLIENT_STATUS_CHECK_REQUEST,
  clientStatusCheckSuccess,
  clientStatusCheckError,
  CLIENT_TRANSACTIONS_REQUEST,
  clientTransactionsSuccess,
  clientTransactionsError,
} from '@actions/client';

import { fetchClientStatus, fetchClientTransactions } from '@services/client';
import { AnyAction } from 'redux';

export function* clientStatusCheck(): SagaIterator {
  try {
    const response = yield call(fetchClientStatus);
    yield put(
      clientStatusCheckSuccess({
        status: response.data.status,
      }),
    );
  } catch (error) {
    yield put(
      clientStatusCheckError({
        status: false,
      }),
    );
  }
}

export function* clientTransactions(action: AnyAction): SagaIterator {
  try {
    const { pageNumber } = action.payload;
    const response = yield call(fetchClientTransactions, pageNumber);
    yield put(
      clientTransactionsSuccess({
        transactions: response.data,
      }),
    );
  } catch (error) {
    yield put(
      clientTransactionsError({
        error: error.response?.data?.message || error.message,
        status: error.response?.status,
      }),
    );
  }
}

export function* watchClientRequests(): SagaIterator {
  yield takeLatest(CLIENT_STATUS_CHECK_REQUEST, clientStatusCheck);
  yield takeLatest(CLIENT_TRANSACTIONS_REQUEST, clientTransactions);
}
