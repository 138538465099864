import { fork, all } from 'redux-saga/effects';

import { watchSessionRequests } from './session';
import { watchProfileRequests } from './profile';
import { watchEmailVerificationRequests } from './email-verification';
import { watchCustomersRequests } from './customers';
import { watchCustomerRequests } from './customer';
import { watchCompanyRequests } from './company';
import { watchDataProvidersRequests } from './data-providers';
import { watchClientRequests } from './client';
import { watchTopUpRequests } from './top-up';

export default function* rootSaga() {
  yield all([
    fork(watchSessionRequests),
    fork(watchProfileRequests),
    fork(watchEmailVerificationRequests),
    fork(watchCustomersRequests),
    fork(watchCustomerRequests),
    fork(watchCompanyRequests),
    fork(watchDataProvidersRequests),
    fork(watchClientRequests),
    fork(watchTopUpRequests),
  ]);
}
