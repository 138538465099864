import { createAction } from '@reduxjs/toolkit';

export const CUSTOMERS_FETCH_REQUEST = 'CUSTOMERS_FETCH_REQUEST';
export const CUSTOMERS_FETCH_SUCCESS = 'CUSTOMERS_FETCH_SUCCESS';
export const CUSTOMERS_FETCH_ERROR = 'CUSTOMERS_FETCH_ERROR';
export const customersFetchRequest: any = createAction(CUSTOMERS_FETCH_REQUEST);
export const customersFetchSuccess: any = createAction(CUSTOMERS_FETCH_SUCCESS);
export const customersFetchError: any = createAction(CUSTOMERS_FETCH_ERROR);

export const CUSTOMER_FETCH_REQUEST = 'CUSTOMER_FETCH_REQUEST';
export const CUSTOMER_FETCH_SUCCESS = 'CUSTOMER_FETCH_SUCCESS';
export const CUSTOMER_FETCH_ERROR = 'CUSTOMER_FETCH_ERROR';
export const customerFetchRequest: any = createAction(CUSTOMER_FETCH_REQUEST);
export const customerFetchSuccess: any = createAction(CUSTOMER_FETCH_SUCCESS);
export const customerFetchError: any = createAction(CUSTOMER_FETCH_ERROR);

export const CUSTOMER_RESET_REQUEST = 'CUSTOMER_RESET_REQUEST';
export const customerResetRequest: any = createAction(CUSTOMER_RESET_REQUEST);

export const CUSTOMER_VERIFY_REQUEST = 'CUSTOMER_VERIFY_REQUEST';
export const CUSTOMER_VERIFY_SUCCESS = 'CUSTOMER_VERIFY_SUCCESS';
export const CUSTOMER_VERIFY_ERROR = 'CUSTOMER_VERIFY_ERROR';
export const customerVerifyRequest: any = createAction(CUSTOMER_VERIFY_REQUEST);
export const customerVerifySuccess: any = createAction(CUSTOMER_VERIFY_SUCCESS);
export const customerVerifyError: any = createAction(CUSTOMER_VERIFY_ERROR);
