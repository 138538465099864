/* eslint-disable no-param-reassign */
import { AnyAction } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import produce, { Draft } from 'immer';

import { logoutRequest } from '@actions/session';
import {
  clientStatusCheckRequest,
  clientStatusCheckSuccess,
  clientStatusCheckError,
  clientTransactionsRequest,
  clientTransactionsSuccess,
  clientTransactionsError,
} from '@actions/client';

export default createReducer(
  {},
  {
    [clientStatusCheckRequest]: produce((state: Draft<any>) => {
      state.requestInProgress = true;
      state.error = '';
    }),
    [clientStatusCheckSuccess]: produce((state: Draft<any>, action: AnyAction) => ({
      requestInProgress: false,
      ...action.payload,
    })),
    [clientStatusCheckError]: produce((state: Draft<any>, action: AnyAction) => {
      state.requestInProgress = false;
      state.error = action.payload.error;
      state.status = false;
    }),
    [clientTransactionsRequest]: produce((state: Draft<any>) => {
      state.requestInProgress = true;
      state.error = '';
    }),
    [clientTransactionsSuccess]: produce((state: Draft<any>, action: AnyAction) => {
      let { result } = action.payload.transactions;
      if (state.result) {
        result = [...state.result, ...result];
      }
      return {
        ...state,
        requestInProgress: false,
        ...action.payload.transactions,
        result,
      };
    }),
    [clientTransactionsError]: produce((state: Draft<any>, action: AnyAction) => {
      state.requestInProgress = false;
      state.error = action.payload.error;
    }),
    [logoutRequest]: produce(() => ({})),
  },
);
