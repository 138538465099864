import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { profileFetchRequest } from '@actions/profile';
import { clientStatusCheckRequest } from '@actions/client';
import Loader from '@components/loader';
import Error from '@pages/error/components/root';

type StateProps = {
  loading: boolean;
  error: string;
  isProfileFetched: boolean;
};

export type DispatchProps = {
  actions: {
    profileFetchRequest: () => void;
    clientStatusCheckRequest: () => void;
  };
};

export default function withUserProfile<P extends object>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<P> {
  const Component = ({
    loading,
    isProfileFetched,
    actions,
    error,
    ...rest
  }: StateProps & DispatchProps) => {
    React.useEffect(() => {
      if (!isProfileFetched) {
        actions.profileFetchRequest();
        actions.clientStatusCheckRequest();
      }
    }, []);

    if (loading || (!isProfileFetched && !error)) {
      return <Loader message="Fetching user profile..." />;
    }

    if (error) {
      return <Error message="Couldn't retrieve user profile data. Please try again later." />;
    }

    return <WrappedComponent {...(rest as P)} />;
  };

  const mapStateToProps = (state: Goaco.State): StateProps => ({
    loading: state.userProfile.requestInProgress,
    error: state.userProfile.error,
    isProfileFetched: !!state.userProfile.email,
  });

  const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    actions: {
      ...bindActionCreators(
        {
          profileFetchRequest,
          clientStatusCheckRequest,
        },
        dispatch,
      ),
    },
  });

  return connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(Component);
}
