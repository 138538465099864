import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';

export const ThemeSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default (theme) => makeStyles({
  footerWrapper: {
    padding: '16px',
    backgroundColor: theme.palette.background.default,
  },
  footer: {
    maxWidth: 800,
    margin: '0 auto',
  },
  link: {
    marginRight: 16,
    '&:last-child': {
      marginRight: 0,
    },
  },
  textWrapper: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-end',
      textAlign: 'left',
    },
  },
  linksWrapper: {
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
});
