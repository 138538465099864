import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  smallBtn: {
    fontSize: '1.8rem',
  },
  largeBtn: {
    fontSize: '2rem',
  },
});
