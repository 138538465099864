import { SagaIterator } from 'redux-saga';
import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';

import {
  SILENT_COMPANY_FETCH_SUCCESS,
  COMPANY_FETCH_REQUEST,
  companyFetchSuccess,
  companyFetchError,
  COMPANY_TRANSACTIONS_DOWNLOAD_REQUEST,
} from '@actions/company';

import {
  fetchCompanyBalance,
  fetchCompanyTransactions,
  fetchCompanyTransactionsCSV,
} from '@services/company';

import { saveCSV } from '@utils/downloadFile';

export function* companyRequest(): SagaIterator {
  try {
    const responses = yield all([call(fetchCompanyBalance), call(fetchCompanyTransactions)]);
    yield put(
      companyFetchSuccess({
        formattedBalance: responses[0].data.formattedBalance,
        transactions: responses[1].data.entries,
      }),
    );
  } catch (error) {
    yield put(
      companyFetchError({
        error: error.response?.data?.message || error.message,
        status: error.response?.status,
      }),
    );
  }
}

export function* downloadTransactionsRequest(): SagaIterator {
  try {
    const responses = yield call(fetchCompanyTransactionsCSV);
    saveCSV(responses);
  } catch (error) {
    yield put(
      companyFetchError({
        error: error.response?.data?.message || error.message,
        status: error.response?.status,
      }),
    );
  }
}

export function* watchCompanyRequests(): SagaIterator {
  yield takeLatest(COMPANY_FETCH_REQUEST, companyRequest);
  yield takeLatest(COMPANY_TRANSACTIONS_DOWNLOAD_REQUEST, downloadTransactionsRequest);
  yield takeLatest(SILENT_COMPANY_FETCH_SUCCESS, companyRequest);
}
