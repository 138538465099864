import { createAction } from '@reduxjs/toolkit';

export const COMPANY_FETCH_REQUEST = 'COMPANY_FETCH_REQUEST';
export const SILENT_COMPANY_FETCH_SUCCESS = 'SILENT_COMPANY_FETCH_SUCCESS';
export const COMPANY_FETCH_SUCCESS = 'COMPANY_FETCH_SUCCESS';
export const COMPANY_FETCH_ERROR = 'COMPANY_FETCH_ERROR';
export const COMPANY_TRANSACTIONS_DOWNLOAD_REQUEST = 'COMPANY_TRANSACTIONS_DOWNLOAD_REQUEST';
export const ENABLE_BALANCE_REFRESH = 'ENABLE_BALANCE_REFRESH';
export const silentCompanyFetchRequest: any = createAction(SILENT_COMPANY_FETCH_SUCCESS);
export const companyFetchRequest: any = createAction(COMPANY_FETCH_REQUEST);
export const companyFetchSuccess: any = createAction(COMPANY_FETCH_SUCCESS);
export const companyFetchError: any = createAction(COMPANY_FETCH_ERROR);
export const downloadTransactionsRequest: any = createAction(COMPANY_TRANSACTIONS_DOWNLOAD_REQUEST);
export const enableBalanceRefresh: any = createAction(ENABLE_BALANCE_REFRESH);
