import React from 'react';
import { Link } from 'react-router-dom';
import {
  IconButton,
  Typography,
  Grid,
  Slide,
} from '@material-ui/core';
import { MenuRounded } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';

import SVG from '@components/svg';
import { Logo2 } from '@assets';
import { DASHBOARD_PATH } from '@routes';
import Navigation from '../../containers/navigation';

import styles from './styles';

const Header: React.FC<{}> = () => {
  const theme = useTheme();
  const classes = styles(theme)();
  const [isNavigationOpen, setNavigationOpen] = React.useState<boolean>(false);

  const toggleNavigation = () => {
    setNavigationOpen(!isNavigationOpen);
  };

  return (
    <Grid
      container
      component="header"
      alignItems="center"
      justify="flex-end"
      className={classes.header}
    >
      <Link to={DASHBOARD_PATH}>
        <Typography
          variant="button"
          align="center"
          color="secondary"
          className={classes.logo}
        >
          <SVG>{Logo2}</SVG>
        </Typography>
      </Link>
      <div className={classes.desktopNavigation}>
        <Navigation screenSize="large" />
      </div>
      <Slide direction="left" in={isNavigationOpen} mountOnEnter unmountOnExit>
        <div className={classes.mobileNavigation}>
          <Navigation screenSize="small" />
        </div>
      </Slide>
      <div className={classes.toggleNavigationBtn}>
        <IconButton aria-label="toggle-navigation" onClick={toggleNavigation}>
          <MenuRounded fontSize="large" />
        </IconButton>
      </div>
    </Grid>
  );
};

export default Header;
