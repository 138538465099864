import { createMuiTheme } from '@material-ui/core/styles';

export enum Themes {
  LIGHT = 'light',
  DARK = 'dark',
}

type Theme = {
  paletteType: Themes;
};

export default (theme: Theme) => createMuiTheme({
  typography: {
    fontFamily: [
      'Source Sans Pro',
      'sans-serif',
    ].join(','),
    htmlFontSize: 10,
  },
  palette: {
    type: theme.paletteType,
    background: {
      default: theme.paletteType === 'dark' ? '#1c1c1e' : '#fefefe',
    },
    primary: {
      main: '#7eb0d5',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#fd9526',
      contrastText: '#ffffff',
    },
    error: {
      main: '#ef705f',
    },
    success: {
      main: '#388e3c',
      contrastText: '#ffffff',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
        },
        '#root': {
          height: '100%',
          'overflow-x': 'hidden',
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: '8px',
      },
      label: {
        fontWeight: 600,
        textTransform: 'none',
      },
      sizeSmall: {
        fontSize: '1.6rem',
      },
      sizeLarge: {
        fontSize: '2rem',
      },
    },
    MuiListItem: {
      root: {
        backgroundColor: theme.paletteType === 'dark' ? '#424242' : '#eeeeee',
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#fd9526',
        },
      },
    },
  },
});
