import React from 'react';
import { Grid, Link, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import { CUSTOMERS_PATH, VERIFY_USER_PATH, CLIENT_PATH } from '@routes';
import ConfigContext from '@context/config';

import styles from './styles';

type Props = {
  isUserAuthenticated: boolean;
  manualVisitSubscriptionEnabled: boolean;
  hasCustomers: boolean;
  actions: {
    logoutRequest: () => void;
  };
  screenSize: 'small' | 'large';
};

const Navigation: React.FC<Props> = ({
  isUserAuthenticated,
  hasCustomers,
  actions,
  screenSize,
  manualVisitSubscriptionEnabled,
}: Props) => {
  const classes = styles();
  const { supportUrl } = React.useContext(ConfigContext);

  return (
    <Grid
      component="nav"
      direction={screenSize === 'small' ? 'column' : 'row'}
      container
      spacing={2}
      alignItems="center"
    >
      {hasCustomers && (
        <>
          <Grid item>
            <Link
              component={RouterLink}
              to={VERIFY_USER_PATH}
              color="textPrimary"
              className={screenSize === 'small' ? classes.largeBtn : classes.smallBtn}
            >
              Verify user
            </Link>
          </Grid>
          <Grid item>
            <Link
              component={RouterLink}
              to={CUSTOMERS_PATH}
              color="textPrimary"
              className={screenSize === 'small' ? classes.largeBtn : classes.smallBtn}
            >
              Customers
            </Link>
          </Grid>
        </>
      )}
      {manualVisitSubscriptionEnabled && (
        <Grid item>
          <Link
            component={RouterLink}
            to={CLIENT_PATH}
            color="textPrimary"
            className={screenSize === 'small' ? classes.largeBtn : classes.smallBtn}
          >
            Manual Visits
          </Link>
        </Grid>
      )}
      {isUserAuthenticated && (
        <>
          <Grid item>
            <Link
              href={supportUrl}
              target="_blank"
              rel="noopener noreferrer"
              color="textPrimary"
              className={screenSize === 'small' ? classes.largeBtn : classes.smallBtn}
            >
              Support
            </Link>
          </Grid>
          <Grid item>
            <Button
              component={Link}
              onClick={actions.logoutRequest}
              className={screenSize === 'small' ? classes.largeBtn : classes.smallBtn}
            >
              Logout
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Navigation;
