import { AnyAction, Dispatch } from 'redux';

import { LOGOUT_REQUEST } from '@actions/session';

export default () => (next: Dispatch) => (action: AnyAction) => {
  const { type, payload, meta } = action;

  if (type.match(/ERROR/)) {
    return payload.status === 401 && !payload.noRedirect
      ? next({
        type: LOGOUT_REQUEST,
        reason: 'You\'ve been logged out due to an unauthorized action or due to long inactivity.',
      })
      : next({
        type,
        payload: {
          error: payload.error === 'Network Error' || payload.status === 500
            ? 'Something happened on our end. Please try again later.'
            : payload.error,
        },
        meta,
      });
  }

  return next(action);
};
