import axios from 'axios';

const COMPANY_BALANCE_URL = '/company/balance';
const CUSTOMER_TRANSACTIONS_URL = '/company/transactions';
const CUSTOMER_TRANSACTIONS_DOWNLOAD_URL = '/record-visit/export-csv-data';

export const fetchCompanyBalance = (): Promise<any> => (
  axios.get(COMPANY_BALANCE_URL)
);

export const fetchCompanyTransactions = (): Promise<any> => (
  axios.get(CUSTOMER_TRANSACTIONS_URL)
);

export const fetchCompanyTransactionsCSV = (): Promise<any> => (
  axios.post(CUSTOMER_TRANSACTIONS_DOWNLOAD_URL)
);
