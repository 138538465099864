import React from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import { CssBaseline } from '@material-ui/core';
import { createBrowserHistory } from 'history';
import initAxios, { setAuthorizationHeader } from '@config/axios';
import routes from '@routes';
import errorsMiddleware from '@middleware/errors';
import sessionMiddleware from '@middleware/session';
import createRedirectMiddleware from '@middleware/redirect';
import rootReducer from '@reducers';
import rootSaga from '@sagas';
import App from '@app/components/root';
import ThemeProvider from '@hoc/theme-provider';
import { CookiesKeys, getCookie } from '@utils/cookies';
import ConfigContext from '@context/config';

initAxios({
  baseUrl: process.env.API_URL,
});

const history = createBrowserHistory();

const sessionToken = getCookie(CookiesKeys.sessionToken);
if (sessionToken) {
  setAuthorizationHeader(sessionToken);
}

const initialState = {
  session: {
    token: sessionToken,
  },
};

const sagaMiddleware = createSagaMiddleware();
const redirectMiddleware = createRedirectMiddleware(history);

const store = configureStore({
  reducer: rootReducer,
  middleware: [
    sagaMiddleware,
    errorsMiddleware,
    sessionMiddleware,
    redirectMiddleware,
  ],
  preloadedState: initialState,
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

render(
  <Provider store={store}>
    <Router history={history}>
      <ThemeProvider>
        <CssBaseline />
        <ConfigContext.Provider
          value={{
            supportUrl: process.env.SUPPORT_URL,
            privacyPolicyUrl: process.env.PRIVACY_POLICY_URL,
            websiteUrl: process.env.WEBSITE_URL,
            feedbackUrl: process.env.FEEDBACK_URL,
          }}
        >
          <App routes={routes} />
        </ConfigContext.Provider>
      </ThemeProvider>
    </Router>
  </Provider>,
  document.getElementById('root'),
);
