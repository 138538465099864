/* eslint-disable no-param-reassign */
import { AnyAction } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import produce, { Draft } from 'immer';

import { logoutRequest } from '@actions/session';
import {
  customersFetchRequest,
  customersFetchSuccess,
  customersFetchError,
} from '@actions/customers';

export default createReducer({}, {
  [customersFetchRequest]: produce((state: Draft<Goaco.CustomersList>) => {
    state.requestInProgress = true;
    state.error = '';
  }),
  [customersFetchSuccess]: produce((state: Draft<Goaco.CustomersList>, action: AnyAction) => {
    state.requestInProgress = false;
    state.list = action.payload.list;
  }),
  [customersFetchError]: produce((state: Draft<Goaco.CustomersList>, action: AnyAction) => {
    state.requestInProgress = false;
    state.error = action.payload.error;
  }),
  [logoutRequest]: produce(() => ({})),
});
