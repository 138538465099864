import axios from 'axios';

const CUSTOMERS_URL = '/company/customers';
const CUSTOMER_URL = '/company/customer/{id}';
const CUSTOMER_ACTIVITY_URL = '/company/customer/{id}/kyc-journey';
const CUSTOMER_VERIFY_URL = '/verify/create';

type VerifyCustomerValues = {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  kycStageType: string[];
};

export const fetchCustomersList = (): Promise<any> => (
  axios.get(CUSTOMERS_URL)
);

export const fetchCustomerDetails = (id: string): Promise<any> => (
  axios.get(CUSTOMER_URL.replace('{id}', id))
);

export const fetchCustomerActivity = (id: string): Promise<any> => (
  axios.get(CUSTOMER_ACTIVITY_URL.replace('{id}', id))
);

export const verifyCustomer = (values: VerifyCustomerValues): Promise<any> => (
  axios.post(CUSTOMER_VERIFY_URL, { ...values })
);
