import axios from 'axios';

const CLIENT_STATUS_CHECK_URL = '/service-status-check/record-a-visit-lite';
const CLIENT_TRANSACTIONS_URL = '/company/manual-record-visit/per-page/20/page/pageNumber';

export const fetchClientStatus = (): Promise<any> => axios.get(CLIENT_STATUS_CHECK_URL);
export function fetchClientTransactions(pageNumber): Promise<any> {
  const url = CLIENT_TRANSACTIONS_URL.replace('pageNumber', pageNumber);
  return axios.get(url);
}
