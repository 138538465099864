/* eslint-disable no-param-reassign */
import { AnyAction } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import produce, { Draft } from 'immer';

import {
  topUpRequest,
  topUpSuccess,
  topUpError,
} from '@actions/top-up';

export default createReducer({}, {
  [topUpRequest]: produce((state: Draft<Goaco.TopUp>) => {
    state.requestInProgress = true;
    state.error = '';
  }),
  [topUpSuccess]: produce((state: Draft<Goaco.TopUp>, action: AnyAction) => ({
    ...state,
    requestInProgress: false,
    error: '',
    ...action.payload,
  })),
  [topUpError]: produce((state: Draft<Goaco.TopUp>, action: AnyAction) => {
    state.requestInProgress = false;
    state.error = action.payload.error;
  }),
});
