import { SagaIterator } from 'redux-saga';
import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import {
  CUSTOMERS_FETCH_REQUEST,
  customersFetchSuccess,
  customersFetchError,
} from '@actions/customers';
import {
  fetchCustomersList,
} from '@services/customers';
import { formatObjectToCamelCase } from '@utils/formatters';

const parseCustomersList = (data) => (
  Object.keys(data).reduce((acc, curr) => (
    { ...acc, [data[curr].uuid]: formatObjectToCamelCase(data[curr]) }
  ), {})
);

export function* customersListRequest(): SagaIterator {
  try {
    const { data } = yield call(fetchCustomersList);
    yield put(customersFetchSuccess({ list: parseCustomersList(data) }));
  } catch (error) {
    yield put(customersFetchError({
      error: error.response?.data?.message || error.message,
      status: error.response?.status,
    }));
  }
}

export function* watchCustomersRequests(): SagaIterator {
  yield takeLatest(CUSTOMERS_FETCH_REQUEST, customersListRequest);
}
