import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import {
  takeLatest,
  call,
  put,
  all,
} from 'redux-saga/effects';

import {
  CUSTOMER_FETCH_REQUEST,
  customerFetchSuccess,
  customerFetchError,
  CUSTOMER_VERIFY_REQUEST,
} from '@actions/customers';
import { redirect } from '@actions';
import {
  fetchCustomerDetails,
  fetchCustomerActivity,
  verifyCustomer,
} from '@services/customers';
import { formatApiErrors } from '@utils/formatters';
import { CUSTOMER_BASE_PATH } from '@routes';

export function* customerRequest(action: AnyAction): SagaIterator {
  const { customerId } = action.payload;

  try {
    const responses = yield all([
      call(fetchCustomerDetails, customerId),
      call(fetchCustomerActivity, customerId),
    ]);
    yield put(customerFetchSuccess({
      details: responses[0].data,
      activity: responses[1].data,
    }));
  } catch (error) {
    yield put(customerFetchError({
      error: error.response?.data?.message || error.message,
      status: error.response?.status,
    }));
  }
}

export function* verifyCustomerRequest(action: AnyAction): SagaIterator {
  const {
    values,
    onError,
  } = action.payload;

  try {
    const response = yield call(verifyCustomer, values);
    yield put(redirect({
      redirectPath: `${CUSTOMER_BASE_PATH}/${response.data.user.uuid}`,
    }));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorsList = error.response?.data?.form?.children || {};
    onError({ errorMessage, errorsList: formatApiErrors(errorsList) });
  }
}

export function* watchCustomerRequests(): SagaIterator {
  yield takeLatest(CUSTOMER_FETCH_REQUEST, customerRequest);
  yield takeLatest(CUSTOMER_VERIFY_REQUEST, verifyCustomerRequest);
}
