import React from 'react';

type Config = {
  supportUrl?: string;
  privacyPolicyUrl?: string;
  websiteUrl?: string;
  feedbackUrl?: string;
}

const ConfigContext = React.createContext<Config>({});

export default ConfigContext;
