import { createAction } from '@reduxjs/toolkit';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const loginRequest: any = createAction(LOGIN_REQUEST);
export const loginSuccess: any = createAction(LOGIN_SUCCESS);
export const loginError: any = createAction(LOGIN_ERROR);

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const logoutRequest: any = createAction(LOGOUT_REQUEST);

export const PASSWORD_RESET_LINK_REQUEST = 'PASSWORD_RESET_LINK_REQUEST';
export const PASSWORD_RESET_LINK_SUCCESS = 'PASSWORD_RESET_LINK_SUCCESS';
export const PASSWORD_RESET_LINK_ERROR = 'PASSWORD_RESET_LINK_ERROR';
export const passwordResetLinkRequest: any = createAction(PASSWORD_RESET_LINK_REQUEST);
export const passwordResetLinkSuccess: any = createAction(PASSWORD_RESET_LINK_SUCCESS);
export const passwordResetLinkError: any = createAction(PASSWORD_RESET_LINK_ERROR);

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';
export const passwordResetRequest: any = createAction(PASSWORD_RESET_REQUEST);
export const passwordResetSuccess: any = createAction(PASSWORD_RESET_SUCCESS);
export const passwordResetError: any = createAction(PASSWORD_RESET_ERROR);
