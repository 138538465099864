import { AnyAction, Dispatch } from 'redux';
import { History } from 'history';

export default (history: History) => () => (next: Dispatch) => (action: AnyAction) => {
  const { payload, meta } = action;

  const { redirectPath } = payload || meta || {};

  if (redirectPath) {
    history.push(redirectPath);
  }

  return next(action);
};
