/* eslint-disable no-param-reassign */
import { AnyAction } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import produce, { Draft } from 'immer';

import { logoutRequest } from '@actions/session';
import {
  kycChecksFetchRequest,
  kycChecksFetchSuccess,
  kycChecksFetchError,
} from '@actions/data-providers';

export default createReducer({
  kycChecks: {},
}, {
  [kycChecksFetchRequest]: produce((state: Draft<Goaco.DataProviders>) => {
    state.kycChecks.requestInProgress = true;
    state.kycChecks.error = '';
  }),
  [kycChecksFetchSuccess]: produce((state: Draft<Goaco.DataProviders>, action: AnyAction) => {
    state.kycChecks.requestInProgress = false;
    state.kycChecks.list = action.payload.list;
  }),
  [kycChecksFetchError]: produce((state: Draft<Goaco.DataProviders>, action: AnyAction) => {
    state.kycChecks.requestInProgress = false;
    state.kycChecks.error = action.payload.error;
  }),
  [logoutRequest]: produce(() => ({
    kycChecks: {},
  })),
});
