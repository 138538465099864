import { SagaIterator } from 'redux-saga';
import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { AnyAction } from 'redux';

import {
  TOP_UP_REQUEST,
  topUpSuccess,
  topUpError,
} from '@actions/top-up';
import {
  topUp,
} from '@services/payments';

export function* topUpRequest(action: AnyAction): SagaIterator {
  try {
    const { data } = yield call(topUp, action.payload);
    yield put(topUpSuccess(data));
  } catch (error) {
    yield put(topUpError({
      error: error.response?.data?.message || error.message,
      status: error.response?.status,
    }));
  }
}

export function* watchTopUpRequests(): SagaIterator {
  yield takeLatest(TOP_UP_REQUEST, topUpRequest);
}
