import axios from 'axios';

const LOGIN_URL = '/login';
const FORGOT_PASSWORD_URL = '/forgot-password';
const RESET_PASSWORD_URL = '/reset-password';

type LoginValues = {
  username: string;
  password: string;
};

type ForgotPasswordValues = {
  email: string;
};

type ResetPasswordValues = {
  token: string;
  password: string;
};

export const login = (values: LoginValues): Promise<any> => (
  axios.post(LOGIN_URL, { ...values })
);

export const sendResetPasswordLink = (values: ForgotPasswordValues): Promise<any> => (
  axios.post(FORGOT_PASSWORD_URL, { ...values })
);

export const resetPassword = (values: ResetPasswordValues): Promise<any> => (
  axios.post(RESET_PASSWORD_URL, { ...values })
);
