import React from 'react';
import { Typography, Grid } from '@material-ui/core';

import styles from './styles';

type Props = {
  message: string;
}

const Error: React.FC<Props> = ({ message }: Props) => {
  const classes = styles();

  return (
    <Grid container direction="column" alignContent="center">
      <section className={classes.section}>
        <Typography component="h1" variant="h5" color="error" align="center">
          {message}
        </Typography>
      </section>
    </Grid>
  );
};

export default Error;
