import { createAction } from '@reduxjs/toolkit';

export const CLIENT_STATUS_CHECK_REQUEST = 'CLIENT_STATUS_CHECK_REQUEST';
export const CLIENT_STATUS_CHECK_SUCCESS = 'CLIENT_STATUS_CHECK_SUCCESS';
export const CLIENT_STATUS_CHECK_ERROR = 'CLIENT_STATUS_CHECK_ERROR';
export const clientStatusCheckRequest: any = createAction(CLIENT_STATUS_CHECK_REQUEST);
export const clientStatusCheckSuccess: any = createAction(CLIENT_STATUS_CHECK_SUCCESS);
export const clientStatusCheckError: any = createAction(CLIENT_STATUS_CHECK_ERROR);

export const CLIENT_TRANSACTIONS_REQUEST = 'CLIENT_TRANSACTIONS_REQUEST';
export const CLIENT_TRANSACTIONS_SUCCESS = 'CLIENT_TRANSACTIONS_SUCCESS';
export const CLIENT_TRANSACTIONS_ERROR = 'CLIENT_TRANSACTIONS_ERROR';
export const clientTransactionsRequest: any = createAction(CLIENT_TRANSACTIONS_REQUEST);
export const clientTransactionsSuccess: any = createAction(CLIENT_TRANSACTIONS_SUCCESS);
export const clientTransactionsError: any = createAction(CLIENT_TRANSACTIONS_ERROR);
