import { SagaIterator } from 'redux-saga';
import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { AnyAction } from 'redux';

import {
  EMAIL_VERIFICATION_REQUEST,
  emailVerificationSuccess,
  emailVerificationError,
} from '@actions/email-verification';
import {
  sendVerificationEmail,
} from '@services/profile';

export function* emailVerificationRequest(action: AnyAction): SagaIterator {
  try {
    yield call(sendVerificationEmail, action.payload);
    yield put(emailVerificationSuccess());
  } catch (error) {
    yield put(emailVerificationError({
      error: error.response?.data?.message || error.message,
      status: error.response?.status,
    }));
  }
}

export function* watchEmailVerificationRequests(): SagaIterator {
  yield takeLatest(EMAIL_VERIFICATION_REQUEST, emailVerificationRequest);
}
