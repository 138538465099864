import React from 'react';

import withSuspense from '@hoc/with-suspense';
import withAuthentication from '@hoc/with-authentication';
import withUserProfile from '@hoc/with-user-profile';
import withCustomers from '@hoc/with-customers';
import withClient from '@hoc/with-client';
import withCustomer from '@hoc/with-customer';
import withCompany from '@hoc/with-company';
import withKycChecks from '@hoc/with-kyc-checks';

const Landing = React.lazy(() => import('@pages/landing/components/root'));
const Login = React.lazy(() => import('@pages/login/components/root'));
const ForgotPassword = React.lazy(() => import('@pages/forgot-password/components/root'));
const ResetPassword = React.lazy(() => import('@pages/reset-password/components/root'));
const Dashboard = React.lazy(() => import('@pages/dashboard/containers/root'));
const Customers = React.lazy(() => import('@pages/customers-list/containers/root'));
const Customer = React.lazy(() => import('@pages/customer/containers/root'));
const Client = React.lazy(() => import('@pages/client/containers/root'));
const VerifyCustomer = React.lazy(() => import('@pages/verify-user/components/root'));

export const LANDING_PATH = '/';
export const AUTHENTICATION_PATH = '/login';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const RESET_PASSWORD_PATH = '/reset-password/:token';
export const DASHBOARD_PATH = '/home';
export const CUSTOMERS_PATH = '/customers';
export const CUSTOMER_BASE_PATH = '/customer';
export const CUSTOMER_PATH = `${CUSTOMER_BASE_PATH}/:id`;
export const VERIFY_USER_PATH = '/verify-user';
export const CLIENT_PATH = '/client';

export default [
  {
    path: LANDING_PATH,
    exact: true,
    component: withAuthentication(withSuspense(Landing), false, false),
  },
  {
    path: AUTHENTICATION_PATH,
    component: withAuthentication(withSuspense(Login), false, false),
  },
  {
    path: FORGOT_PASSWORD_PATH,
    component: withAuthentication(withSuspense(ForgotPassword), false, false),
  },
  {
    path: RESET_PASSWORD_PATH,
    component: withAuthentication(withSuspense(ResetPassword), false, false),
  },
  {
    path: DASHBOARD_PATH,
    component:
      withAuthentication(withUserProfile(withCompany(withSuspense(Dashboard))), true),
  },
  {
    path: CUSTOMERS_PATH,
    component: withAuthentication(withUserProfile(withCustomers(withSuspense(Customers))), true),
  },
  {
    path: CUSTOMER_PATH,
    component: withAuthentication(withUserProfile(withCustomer(withSuspense(Customer))), true),
  },
  {
    path: CLIENT_PATH,
    component: withAuthentication(withUserProfile(withClient(withSuspense(Client))), true),
  },
  {
    path: VERIFY_USER_PATH,
    component: withAuthentication(
      withUserProfile(withKycChecks(withSuspense(VerifyCustomer))),
      true,
    ),
  },
  {
    path: '*',
    component: withAuthentication(withSuspense(Landing), false, false),
  },
];
