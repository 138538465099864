import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { matchRoles } from '@utils/roles';
import { USER_ROLES } from '@constants';
import { logoutRequest } from '@actions/session';
import Navigation from '../../components/navigation';

type StateProps = {
  isUserAuthenticated: boolean;
  hasCustomers: boolean;
  manualVisitSubscriptionEnabled: boolean;
};

type DispatchProps = {
  actions: {
    logoutRequest: () => void;
  };
};

type OwnProps = {
  screenSize: 'small' | 'large';
};

export const mapStateToProps = (state: Goaco.State): StateProps => ({
  isUserAuthenticated: !!state.session?.token,
  manualVisitSubscriptionEnabled: state.client?.status,
  hasCustomers:
    !!state.userProfile.roles
    && matchRoles(state.userProfile.roles, [USER_ROLES.client, USER_ROLES.authoriser]),
});

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    ...bindActionCreators(
      {
        logoutRequest,
      },
      dispatch,
    ),
  },
});

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Navigation);
