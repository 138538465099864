import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles({
  header: {
    position: 'relative',
    padding: '16px 10px',
    height: '100px',
    backgroundColor: theme.palette.background.default,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.type === 'dark' ? '#343434' : '#e7e7e7',
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  logo: {
    width: '154px',
    height: '50px',
    position: 'absolute',
    top: '50%',
    left: '16px',
    transform: 'translate3d(0, -50%, 0)',
    [theme.breakpoints.up('lg')]: {
      left: '50%',
      transform: 'translate3d(-50%, -50%, 0)',
    },
    color: theme.palette.type === 'dark' ? '#fefefe' : '#1c1c1e',
    zIndex: 3,
  },
  desktopNavigation: {
    display: 'none',

    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  mobileNavigation: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.type === 'dark' ? '#1c1c1e' : '#fefefe',
    padding: '100px 16px 16px',
    zIndex: 2,

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },

    MuiButton: {
      fontSize: '2rem',
    },
  },
  toggleNavigationBtn: {
    zIndex: 4,

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
});
