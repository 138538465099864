/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import { Themes } from '@theme';

type Theme = {
  theme: Themes;
  toggleTheme: () => void;
}

const ThemeContext = React.createContext<Theme>({
  theme: Themes.LIGHT,
  toggleTheme: () => {},
});

export default ThemeContext;
