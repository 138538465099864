import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { companyFetchRequest, downloadTransactionsRequest } from '@actions/company';
import Loader from '@components/loader';
import Error from '@pages/error/components/root';
import { matchRoles } from '@utils/roles';
import { USER_ROLES } from '@constants';

type StateProps = {
  loading: boolean;
  error: string;
  shouldFetchCompany: boolean;
}

export type DispatchProps = {
  actions: {
    companyFetchRequest: () => void;
    downloadTransactionsRequest: () => void;
  };
}

export default function withCompany<P extends object>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<P> {
  const Component = ({
    loading,
    shouldFetchCompany,
    actions,
    error,
    ...rest
  }: StateProps & DispatchProps) => {
    React.useEffect(() => {
      if (shouldFetchCompany) {
        actions.companyFetchRequest();
      }
    }, []);
    const props = { ...rest, actions };
    if (shouldFetchCompany && loading && !error) {
      return <Loader message="Fetching company data..." />;
    }

    if (error) {
      return <Error message="Couldn't retrieve company data. Please try again later." />;
    }

    return <WrappedComponent {...props as P} />;
  };

  const mapStateToProps = (state: Goaco.State): StateProps => ({
    loading: state.company.requestInProgress,
    error: state.company.error,
    shouldFetchCompany: matchRoles(
      state.userProfile.roles,
      [USER_ROLES.client, USER_ROLES.authoriser],
    ),
  });

  const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    actions: {
      ...bindActionCreators({
        companyFetchRequest,
        downloadTransactionsRequest,
      }, dispatch),
    },
  });

  return connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(Component);
}
