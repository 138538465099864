import React from 'react';
import { Typography, Grid, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import ThemeContext from '@context/theme';
import ConfigContext from '@context/config';
import { Themes } from '@theme';
import styles, { ThemeSwitch } from './styles';

const Footer: React.FC<{}> = () => {
  const miTheme = useTheme();
  const classes = styles(miTheme)();
  const { theme, toggleTheme } = React.useContext(ThemeContext);
  const { websiteUrl, supportUrl, privacyPolicyUrl } = React.useContext(ConfigContext);

  return (
    <div className={classes.footerWrapper}>
      <Grid
        container
        component="footer"
        alignItems="center"
        direction="column"
        className={classes.footer}
        spacing={1}
      >
        <Grid item>
          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>Light</Grid>
              <Grid item>
                <ThemeSwitch
                  name="theme"
                  checked={theme === Themes.DARK}
                  onChange={toggleTheme}
                />
              </Grid>
              <Grid item>Dark</Grid>
            </Grid>
          </Typography>
        </Grid>
        <Grid container item className={classes.textWrapper}>
          <Grid item xs={12} sm={7}>
            <Typography component="p" variant="caption">
              Goaco Ltd, Level 5, Unit 1-3, Invicta Business Park, Kent, ME8 6PG
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={5} className={classes.linksWrapper}>
            <Link
              href={websiteUrl}
              target="_blank"
              rel="noopener noreferrer"
              color="textPrimary"
              variant="body2"
              className={classes.link}
            >
              Goaco
            </Link>
            <Link
              href={privacyPolicyUrl}
              target="_blank"
              rel="noopener noreferrer"
              color="textPrimary"
              variant="body2"
              className={classes.link}
            >
              Privacy Policy
            </Link>
            <Link
              href={supportUrl}
              target="_blank"
              rel="noopener noreferrer"
              color="textPrimary"
              variant="body2"
              className={classes.link}
            >
              Contact
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
