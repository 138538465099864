import { camelCase, startCase } from 'lodash';
import { format } from 'date-fns';
import numeral from 'numeral';

export const formatObjectToCamelCase = (obj: object) => (
  Object.keys(obj).reduce((acc, curr) => ({
    ...acc,
    [camelCase(curr)]: obj[curr] && typeof obj[curr] === 'object' && !Array.isArray(obj[curr])
      ? formatObjectToCamelCase(obj[curr])
      : obj[curr],
  }), {})
);

export const formatStringToStartCase = (str: string) => (
  startCase(str)
);

export const formatDate = (date: string, dateFormat = 'dd/MM/yyyy HH:mm:ss') => (
  format(new Date(date), dateFormat)
);

export const formatCurrency = (amount: number, currencyFormat = '0.00') => (
  numeral(amount).format(currencyFormat)
);

export const formatApiErrors = (data: any) => (
  Object.keys(data).reduce((acc, curr) => ({
    ...acc,
    [curr]: (data[curr].errors || []).join('/n'),
  }), {})
);
