import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';

import ThemeContext from '@context/theme';
import createTheme, { Themes } from '@theme';
import { getKey, setKey, StorageKeys } from '@utils/storage';

type Props = {
  children: React.ReactNode;
}

const ThemeProvider: React.FC<Props> = ({ children }: Props) => {
  const [theme, setTheme] = React.useState<Themes>(getKey(StorageKeys.theme) || Themes.LIGHT);

  const toggleTheme = () => {
    const updatedTheme = theme === Themes.LIGHT ? Themes.DARK : Themes.LIGHT;
    setTheme(updatedTheme);
    setKey(StorageKeys.theme, updatedTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <MuiThemeProvider theme={createTheme({ paletteType: theme })}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
