/* eslint-disable no-param-reassign */
import { AnyAction } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import produce, { Draft } from 'immer';

import { logoutRequest } from '@actions/session';
import {
  profileFetchRequest,
  profileFetchSuccess,
  profileFetchError,
} from '@actions/profile';

export default createReducer({}, {
  [profileFetchRequest]: produce((state: Draft<Goaco.UserProfile>) => {
    state.requestInProgress = true;
    state.error = '';
  }),
  [profileFetchSuccess]: produce((state: Draft<Goaco.UserProfile>, action: AnyAction) => ({
    ...state,
    requestInProgress: false,
    ...action.payload,
  })),
  [profileFetchError]: produce((state: Draft<Goaco.UserProfile>, action: AnyAction) => {
    state.requestInProgress = false;
    state.error = action.payload.error;
  }),
  [logoutRequest]: produce(() => ({})),
});
