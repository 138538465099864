import Cookies from 'js-cookie';

export enum CookiesKeys {
  sessionToken = 'sessionToken',
}

type CookieOptions = {
  expires?: number;
}

export const setCookie = (key: CookiesKeys, value: any, options: CookieOptions = {}): any => (
  Cookies.set(key, value, { ...options })
);

export const getCookie = (key: CookiesKeys): any => Cookies.get(key);

export const clearCookie = (key: CookiesKeys) => Cookies.remove(key);
