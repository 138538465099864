import { combineReducers } from 'redux';

import session from './session';
import userProfile from './profile';
import emailVerification from './email-verification';
import customers from './customers';
import client from './client';
import customer from './customer';
import company from './company';
import dataProviders from './data-providers';
import topUp from './top-up';

const rootReducer = combineReducers({
  session,
  userProfile,
  emailVerification,
  customers,
  customer,
  company,
  dataProviders,
  client,
  topUp,
});

export default rootReducer;
