import { SagaIterator } from 'redux-saga';
import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import {
  PROFILE_FETCH_REQUEST,
  profileFetchSuccess,
  profileFetchError,
} from '@actions/profile';
import {
  fetchUserProfile,
} from '@services/profile';
import { formatObjectToCamelCase } from '@utils/formatters';

export function* profileRequest(): SagaIterator {
  try {
    const { data } = yield call(fetchUserProfile);
    yield put(profileFetchSuccess({ ...formatObjectToCamelCase(data) }));
  } catch (error) {
    yield put(profileFetchError({
      error: error.response?.data?.message || error.message,
      status: error.response?.status,
    }));
  }
}

export function* watchProfileRequests(): SagaIterator {
  yield takeLatest(PROFILE_FETCH_REQUEST, profileRequest);
}
